.register-container {
  // .login-container-inner {
  //     .register-form-container {
  //         height: 490px;
  //     }
  // }

  .forgotten-pswd-container-inner {
    .register-form-container {
      height: 374px;

      .form-heading {
        max-width: 500px;
        padding-right: 10px;
        line-height: 20px;
      }
    }
  }
}

.register-container {
  .guest-location-container-inner {
    .register-form-container {
      height: 450px;

      .form-heading {
        max-width: 500px;
        padding-right: 10px;
      }

      .location-label {
        display: flex;
        gap: 10px;
        border-bottom: 1px solid $small-borders;
        .input-field {
          flex: 1;
          border-bottom: 0;
        }

        .location-image-wrapper {
          position: relative;
          bottom: 2px;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $black;
          border-radius: 100%;
        }
      }
    }
  }
}
