.checkout-delivery-container {
    min-height: 335px;
    padding: 20px;
    border: 1px solid $small-borders;
    border-radius: 8px;

    @include mobile-max-550px {
        padding: 10px 20px;
        height: auto;
    }
    .checkout-delivery-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .checkout-delivery-pricing {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            border-bottom: 1px solid $small-borders;
        }

        .checkout-pricing-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .checkout-pricing {
                text-transform: uppercase;
                @include jost-medium(15px);
                color: $primary;
            }

            .checkout-price {
                text-transform: uppercase;
                @include jost-medium(17px);
                color: $primary;
            }

            .checkout-price-red {
                text-transform: uppercase;
                color: $red;
                @include jost-medium(17px);
            }
        }

        .checkout-payment-method-wrapper {
            width: 100%;

            @include mobile-max-400px {

                flex-direction: column;
                height: 70px;
            }
            .payment {

                input:checked+.delivery-btn  {
                    background-color: $payment-btns;
                    color: $white;
                }
            }
        }

        .order-btn {
            margin: 0 auto;

            .send-message {
                @include jost-medium(13px);
                color: $primary;
            }
        }
    }

    .delivery-restaurant-btns {
        height: 50px;

        @include mobile-458{
            height: 98px;
        }

        .delivery:last-of-type {
            .delivery-btn {
                padding-left: 10px;
            }
        }
    }
}
