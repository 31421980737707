.cart-content {
    position: relative;
    height: 625px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;

    .cart-inner-heading {
        color: $black;
        @include jost-regular(17px);

        @include mobile {
            text-align: center;
        }
    }

    .image-one {
        left: -20% !important;
    }

    .image-two {
        top: 60% !important;
        left: -18% !important;
    }

    .image-three {
        top: -5%;
        right: -11%;
    }

    .image-four {
        top: 24% !important;
        right: -28% !important;
    }
}