.closed-msg {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  @include jost-regular();
  background: #ffd200;
}

.nav-container {
    height: 119px;
    padding: 15px 30px;
    // margin-bottom: 90px;
    background-image: url("../images/black-backgr-gradient.jpg");
    background-size: 100%;

    @include mobile {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
    }

    .login-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        min-width: 26px;
        max-width: 26px;
        border: 1px solid $white;
        border-radius: 50%;
    }

    .language {
        height: 30px;
        display: flex;
        align-items: center;
        gap: 5px;
        border-left: 1px solid $border;
        padding-left: 10px;

        .language-text {
            width: 30px;
            text-transform: uppercase;

            .arrow-wrapper {
                    position: absolute;
                    top: 12px;
                    right: -10px;
            }
        }

        .language-select {
            border: none;
            width: auto;
            padding-left: 0;
            color: $white !important;
            @include jost-light(11px);
        }

        .language-dropdown {
            left: -5px;
            padding: 0;
            width: auto;

            @include jost-light(15px);

            & > .select-dropdown-scrollbar {
                align-items: center;
                max-height: unset;
            }
        }

        .arrow-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .desktop-language-dropdown {
            padding-right: 0;
            top: 47px;
            overflow-y: unset;
            @include jost-light(15px);
            width: unset;

            a {
                justify-content: center;
                padding: 5px;
            }
        }
    }

    .nav-links, .contact-text {

        @include jost-light(13px);
        line-height: 14px;
        text-transform: uppercase;
        color: $white;
    }

    .nav-links {
        &:after {
            content: "";
            display: block;
            margin-top: 10px;
            width: 0;
            height: 1px;
            background-color: $border;
            transition: width 0.8s;
        }

        &:hover::after {
            width: 100%;
        }
    }

    .contact-text {
        display: block;
    }

    .nav-row {

        .contact-wrapper, .profile-cart {
            position: relative;
            top: -5px;

            @include desktop-large {
                top: unset;
            }
        }

        .nav-left, .nav-right {
            @include desktop-large {
                flex-direction: column;
                gap: 10px !important;

            }
        }

        .nav-col {

            .nav-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 70px;

                .nav-left, .nav-right {
                    display: flex;
                    align-items: center;
                    gap: 50px;

                    .nav-links {
                        &:after {
                            content: "";
                            display: block;
                            margin-top: 5px;
                            width: 0;
                            height: 1px;
                            background-color: $border;
                            transition: width 0.8s;
                        }

                        &:hover::after {
                            width: 100%;
                        }
                    }
                }
            }

            .contact-wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .profile-cart-wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 10px;

                .profile-cart {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .login {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .login-text {
                            padding-left: 5px;
                            text-transform: uppercase;
                            @include jost-light(11px);
                            color: $white;
                        }
                    }

                    /* LOGGED IN */
                    .drop {
                        .dropdown-toggle:after {
                            content: none;
                            margin-left: 0;
                        }

                        .dropdown-items {
                            padding: 0;
                            transform: translate(-15px, 0px);
                            display: block;
                            opacity: 0;
                            pointer-events: none;
                            transition: transform 0.8s, opacity 0.8s;

                            .dropdown-wrapper {
                                border-radius: 6px;
                                overflow: hidden;

                                & .dropdown-item:last-of-type .dropdown-image {
                                    position: relative;
                                    left: 1px;
                                }
                            }

                            .dropdowns {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                padding: 10px 0;
                                padding-left: 10px;
                                padding-right: 15px;
                                border-bottom: 1px solid $border;

                                &:last-of-type {
                                    border-bottom: 0;
                                }

                                .dropdown-item-image {
                                    min-width: 24px;
                                    height: 24px;
                                    border: 1px solid $black;
                                    border-radius: 100%;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                .dropdown-text {
                                    @include jost-regular(15px);
                                }
                            }

                            &.show {
                                transform: translate(-15px, 20px) !important;
                                opacity: 1;
                                pointer-events: auto;
                                inset: unset !important;
                            }
                        }

                        .arrow {
                            width: 0px;
                            height: 0px;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 10px solid $white;
                            // background-color: $white;

                            position: absolute;
                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    body.sticky-nav & {
        position: fixed;
        animation-name: stickyNav;
        animation-iteration-count: 1;
        animation-play-state: running;
        animation-duration: 1.6s;
    }
}

.langs {
  position: relative;
  color: #fff;
  font-size: 12px;

    &__btn {
      background: none;
      border: none;
      color: #fff;
      font-weight: 300;
    }

    &__menu {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 10px;
      padding: 4px 0;
      list-style-type: none;
      background-color: #fff;
      border-radius: 6px;
      padding-left: 0;

        &.active {
          display: block;
        }
    }

    a {
      display: block;
      padding: 4px 8px;
    }
}

.nav-container + .secondary-slider-container,.terms-container,.reservation-container,.about-us-container,.contact-us-container {
    margin-top: 50px;
}


