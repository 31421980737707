.error-nav-marg {
  margin-bottom: 200px !important;

  @include mobile {
    margin-bottom: 130px !important;
  }
}

.error-container-outer {
  @include mobile {
    padding: 0 20px;
  }
}

.error-container {
  height: 490px;
  padding: 0;
  margin-bottom: 100px;
  background-image: url("../images/error-bg.png");
  background-color: $white;
  position: relative;
  border-radius: 15px;

  .sad-face-wrapper {
    max-width: 200px;
    width: 30%;
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translateX(-50%);

    z-index: 1;

    @include mobile {
      top: -20%;
    }

    .sad-face-image {
      width: 100%;
    }
  }

  .error-plate-wrapper {
    max-width: 820px;
    width: 100%;
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);

    @include mobile {
      padding: 0 20px;
      top: -10%;
    }

    .plate-image {
      width: 100%;
    }
  }

  .error-text-wrapper {
    height: 100%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 40px;
    .error {
      padding-bottom: 20px;
      @include comforter(90px);
      color: $primary;

      @include mobile {
        font-size: 60px;
      }
    }

    .error-red {
      @include comforter(90px);
      color: $red;

      @include mobile {
        font-size: 70px;
      }
    }

    .error-text {
      padding-bottom: 40px;
      @include jost-semiBold(28px);
      color: $primary;

      @include mobile {
        text-align: center;
      }
    }
  }
}
