.select {
  &-wrapper {
    position: relative;
    width: fit-content;

    .dropdown-arrow-wrapper {
      position: absolute;
      top: 15px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      cursor: pointer;
    }

    .select-dropdown-scrollbar {
      max-height: 110px;
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--bs-primary);
      }
    }

    .form-select {
      padding: 0;
    }
  }

  &-btn {
    width: 240px;
    background: transparent;
    color: $white;
    padding-left: 40px;
    padding-right: 10px;
    pointer-events: none;
    text-transform: uppercase;
    @include jost-light(13px);
  }

  &-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &-dropdown {
    overflow: hidden;
    background-color: $white;

    padding-right: 5px;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;

    border: 1px solid $small-borders;
    border-radius: 8px;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.8s;
    z-index: 10;

    @include jost-regular(15px);

    &.active {
      opacity: 1;
      pointer-events: auto;

      &.active + .select-btn {
        color: $primary !important;
        font-weight: 400 !important;
      }

      &.active + .language-select {
        color: $white !important;
        font-weight: 300 !important;
      }
    }

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid $small-borders;

      &:hover {
        background-color: $hover-bg;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    & span {
      max-width: fit-content;
      color: $black;
    }
  }
}


.single-product-selection-wrapper {

  .select-dropdown {

    &.active + .select-btn {
      font-weight: 500 !important;
    }
  }
}