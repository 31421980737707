.about-nav-marg {
  // margin-bottom: 50px !important;

  @include mobile {
    margin-bottom: 15px !important;
  }
}

.about-us-container {
  background-image: url("../images/qs-backgr-white.jpg");
  padding-top: 35px;
  padding-bottom: 50px;

  .about-us-heading {
    margin: 0 auto;
    padding-left: 210px;
    padding-bottom: 25px;

    @include comforter(60px);
    color: $primary;

    @include mobile {
      padding-left: 0;
      padding-bottom: 20px;
      text-align: center;
      font-size: 50px;
    }
  }

  .slider-wrapper {
    padding: 0 40px;

    @include mobile {
      padding: 0 20px;
    }
    .about-us-slider {
      .swiper-wrapper {
        // padding-bottom: 40px;
        .swiper-slide {
          width: 228px;
          .slider-image-wrapper {
            display: block;
            position: relative;
            padding-top: 76%;
            width: 100%;
            height: 100%;
            border: 1px solid $white;
            border-radius: 10px;
            overflow: hidden;

            .slider-image {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
            }

            img {
              transition: transform 0.6s ease;
            }

            &:hover {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .swiper-scrollbar {
        position: unset !important;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 100%;
        height: 1px;
        background-color: $footer-border;

        .swiper-scrollbar-drag {
          height: 20px;
          top: -9px;
          background-color: $red;

          .arrows-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

.bg-image-container {
  padding: 0 10px;

  @include mobile {
    padding: 0 20px;
    overflow: hidden;
  }

  .about-us-res {
    margin-bottom: 120px;
  }

  .rating-container {
    .learn-more-rating {
      z-index: 1;
      position: relative;
      padding-bottom: 0 !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      flex: 1;

      top: -10px;

      @include mobile-max-1000px {
        top: unset;
      }
      .learn-more-btn-wrapper {
        // padding-bottom: 15px !important;

        @include mobile-max-550px {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .how-col-text {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .main-container {
    background-image: url("../images/about-us-bg.png");
    background-position: center;
    position: relative;
    // height: 560px;
    border-radius: 15px;
    padding: 50px 70px;
    margin-bottom: 100px;

    @include mobile-max-1000px {
      height: auto;
      padding: 20px;
    }
    .swiper {
      margin-bottom: 0;
      // height: 170px;
      overflow: hidden;
      cursor: pointer;

      .slide {
        height: unset;
        background-image: none;

        .client-rating {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .rating-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            @include mobile-max-550px {
              flex-direction: column;
            }

            .client-image-wrapper {
              min-width: 100px;
              height: 100px;
              border: 1px solid $red;
              overflow: hidden;
              border-radius: 100%;

              .client-image {
                width: 100%;
                height: 100%;

                object-fit: cover;
              }
            }

            .client-rating-wrapper {
              position: relative;
              bottom: 8px;
              display: flex;
              flex-direction: column;

              .rating-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .star-rating-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  .star {
                    display: flex;
                  }
                }
              }

              .name {
                color: $primary;
                @include jost-regular(26px);
              }
            }
          }

          .rating,
          .slash,
          .rating-text {
            color: $primary;
            @include jost-regular(22px);
          }

          .rating-text {
            padding-right: 25px;

            @include mobile-max-1000px {
              text-align: center;
              padding: 0;
            }
          }
        }
      }
    }

    .slider-navigation-wrapper {
      top: 50%;
      position: absolute;
      width: 100%;

      .slider-navigation-left {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        left: -100px;
        transform: translateY(-50%);
      }

      .slider-navigation-right {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 45px;
        transform: translateY(-50%);
      }

      .slider-navigation-left,
      .slider-navigation-right {
        width: 8px;
        height: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      border-radius: 15px;
      background: linear-gradient(to top, white, rgba(255, 255, 255, 0.8));
    }
  }

  .slider-text {
    padding: 0 !important;
    // padding-right: 60px !important;
    // padding-top: 100px !important;

    .text-wrapper {
      line-height: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .text-normal {
      text-transform: uppercase;
      @include jost-semiBold(40px);
      color: $primary;

      @include mobile-max-550px {
        font-size: 30px;
      }
    }
    .text-comf {
      color: $primary;
      @include comforter(65px);

      @include mobile-max-550px {
        font-size: 35px;
      }
    }
    .text-comf-red {
      color: $red;
    }
  }
}

.about-image {
  z-index: 1;
}

.about-us-container + .about-us-res {
  margin-bottom: 120px;

  @include mobile {
    margin-bottom: 60px;
  }
}

.about-us-res + .how-heading-container {
  margin-bottom: 0;
  margin-bottom: 70px;

  .how-order-container {
    margin-bottom: 0;
  }
}
