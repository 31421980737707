.delivery-restaurant-btns {
    display: flex;
    align-items: center;
    padding: 0 1px;
    max-width: 380px;
    width: 100%;
    height:35px;
    border: 1px solid $small-borders;
    border-radius: 6px;

    .delivery {
        flex: 1;
        height: 95%;
        cursor: pointer;

        @include mobile-max-400px {
            width: 100%;
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            text-transform: uppercase;
            @include jost-medium(13px);
            color: $primary;
            border-radius: 6px;
            transition: background-color 0.3s;

            &.not-active {
                color: $deliveryDisabled;
            }
        }

        input:checked+.delivery-btn {
            background-color: $red;
            color: $white;
        }
    }
}