.animatable {
    &.animate {
        opacity: 0;
        animation-name: show;
        animation-duration: 1.8s;
        animation-play-state: running;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
    }

    &.animate-close { 
        opacity: 0;
        animation-name: hide;
        animation-duration: 0.8s;
        animation-play-state: running;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
    }
}