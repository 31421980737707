.promo-first-half, .promo-first-half + .used-cashback-wrapper {
  margin-bottom: 70px;

    .table-body-wrapper:first-of-type {
      .table-body-item {
        padding-top: 25px !important;
        padding-bottom: 15px !important;

        @include mobile {
          padding-top: 0 !important;
        }
      }
    }
}

.cashback-heading {
  border-bottom: 1px solid $small-borders;
  margin-bottom: 10px;
}
.margin-bot {
  margin-bottom: 60px;
}
.used-cashback-heading {
  width: 100%;

  .table-head {

    @include mobile {
      display: none;
    }

    .table-head-wrapper {

      .table-head-item {
        padding-top: 10px;
        width: 25%;
      }

      .table-cashback-used {
        padding-top: 10px;
      }
    }
  }

  .table-body {

    .table-body-wrapper {
      border-bottom: 1px solid $small-borders;

      &:first-of-type .cashback-used-promos {
        padding-top: 0;

        @include mobile {
          padding-top: 5px;
        }
      }

      &:first-of-type .table-body-item {
        padding-top: 25px;

        @include mobile {
          padding-top: 0;
        }
      }

      @include mobile {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
      }

      .table-body-item {
        padding: 15px 0;

        @include mobile {
          padding-top: 0;
          padding-bottom: 15px;
        }

        .used-money {
          display: none;
        }
        .table-head-item {
          display: none;
        }
        @include mobile {
          .used-money {
            display: flex;

            .text {
              text-transform: uppercase;
              @include jost-medium(13px);
            }
          }

          .table-head-item, .table-head-heading {
            display: flex;

            .text {
              @include jost-medium(13px !important);
              font-weight: 500 !important;
            }
          }
        }

        .text {
          @include jost-light(17px);
          color: $primary;
        }

        .text-color {
          color: $red;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      & {
        @include mobile {
          padding-top: 10px;
        }
      }
    }
  }

  .used-money {
    display: flex;
    align-items: center;
    gap: 3px;
    .text {
      text-transform: uppercase;
      @include jost-medium(13px);
      color: $primary;
    }

    .arrows-wrapper {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }
}


