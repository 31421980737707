.footer-bg-container {
  // height: 370px;
  padding: 40px 0;
  background-image: url("../images/qs-backgr-white.jpg");

  .footer-container {
    max-width: 1200px;
    padding: 0 10px;

    .footer-row {
      padding-bottom: 50px;
      border-bottom: 1px solid $footer-border;
      margin: 0;

      @include mobile {
        padding-bottom: 30px;
        flex-direction: column;
      }

      .footer-col-left {
        @include mobile {
          order: 2;
          margin-bottom: 40px;
        }

        .footer-col-left-wrapper {
          display: flex;
          flex-direction: column;
          gap: 30px;

          @include mobile {
            align-items: center;
          }

          .footer-left-heading {
            text-transform: uppercase;
            @include jost-semiBold(13px);
            color: $primary;
          }

          .footer-menu-wrapper {
            display: flex;
            align-items: center;
            gap: 100px;
            .footer-menu-left,
            .footer-menu-right {
              display: flex;
              flex-direction: column;
              gap: 5px;

              @include mobile {
                align-items: center;
              }

              .menu-links,
              .nav-links {
                max-width: fit-content;
                text-transform: uppercase;
                @include jost-regular(13px);
                color: $primary;

                &:after {
                  content: "";
                  display: block;
                  margin-top: 0px;
                  width: 0;
                  height: 1px;
                  background-color: $border;
                  transition: width 0.8s;
                }

                &:hover::after {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .footer-col-center {
        text-align: center;
        @include mobile {
          order: 1;
          margin-bottom: 30px;
        }
        .footer-col-center-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;

          .footer-socials {
            display: flex;
            gap: 10px;

            .fb-wrapper,
            .insta-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 26px;
              height: 26px;
              border: 1px solid $secondary;
              border-radius: 50%;
            }
          }
        }
      }

      .footer-col-right {
        @include mobile {
          order: 3;
        }
        .col-right-wrapper {
          display: flex;
          flex-direction: column;

          @include mobile {
            align-items: center;
          }

          .col-right-heading {
            padding-bottom: 20px;
            text-transform: uppercase;
            @include jost-semiBold(13px);
            text-align: end;
            color: $primary;
          }

          .reservations-wrapper {
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            @include mobile {
              flex-direction: column;
              padding-bottom: 20px;
            }

            .reservations-contact-wrapper {
              display: flex;
              align-items: center;
              gap: 5px;

              .contact-image-wrapper {
                height: 26px;
                width: 26px;
                display: flex;
                justify-content: center;
                align-items: center;

                border: 1px solid $secondary;
                border-radius: 50%;
              }

              .contact-numbers {
                @include jost-regular(13px);
                color: $primary;

                .number {
                  display: block;
                }
              }
            }

            .reservations-btn {
              height: 35px;
              width: 160px;
              display: flex;
              justify-content: center;
              align-items: center;

              border: 1px solid $secondary;
              border-radius: 8px;
              text-transform: uppercase;
              @include jost-regular(13px);
              color: $primary;
              transition: 1.2s;

              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }

          .how-to-wrapper {
            padding-bottom: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            text-transform: uppercase;
            @include jost-medium(13px);
            color: $primary;

            @include mobile {
              flex-direction: column;
              padding-bottom: 20px;
            }
          }

          .policy-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;

            @include jost-medium(13px);
            color: $primary;

            @include mobile {
              align-items: center;
            }
          }
        }
      }
    }

    .footer-second-row {
      max-width: 1200px;
      padding: 15px 10px 0;

      @include mobile {
        margin: 0;
        flex-direction: column;
        gap: 30px;
      }
      .footer-end {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        @include jost-regular(13px);
        color: $primary;

        @include mobile {
          justify-content: center !important;
        }

        &:last-of-type {
          justify-content: flex-end;
        }
      }
    }
  }

  .footer-payment-text { 
    margin-top: 25px;
    margin-bottom: 15px;
    @include jost-regular(13px);
    color: $primary;
  }

  .footer-payment-image {
    img {
      width: 268px;
      height: 25px;
      filter: grayscale(1);
    }
  }
}
