.order-popup-container {
  position: fixed;
  top: 70%;
  left: 0;
  z-index: 5;
  transform: translateX(-263px + 73px);
  transition: transform 1s;

  @include mobile {
    top: unset;
    bottom: 20px;
  }

  .order-popup-outer {
    display: flex;
    align-items: center;
  }

  .order-popup-inner {
    padding: 0 15px;
    width: 250px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: $white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .order-popup-time {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      border-bottom: 1px solid $small-borders;

      .order-time-image-wrapper {
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 1px solid $small-borders;
        border-radius: 6px;
      }
    }

    .order-time-text {
      @include jost-light(14px);
      color: $primary;
    }

    .time {
      @include jost-medium(14px);
      color: $show-btn;
    }

    .order-popup-cart {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;

      .order {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .progressive-bar-wrapper {
          position: relative;
          max-width: 169px;
          height: 3px;
          width: 169px;
          background-color: $small-borders;

          .progressive-bar {
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            max-width: 100%;
            background-color: $progressive;
            transition: max-width 1.6s;
          }
        }
      }

      .order-cart-image-wrapper {
        height: 40px;
        max-width: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        border: 1px solid $small-borders;
        border-radius: 6px;

        .number {
          @include jost-light(12px);
        }
      }
    }
  }

  .button-show-order {
    height: 40px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    .arrow-right {
      transition: transform 0.8s;
    }
  }

  &.active {
    transform: translateX(0);
  }

  &.active .arrow-right {
    transform: rotate(180deg);
  }
}
