.people-container {
  position: relative;
  padding-bottom: 65px;
  padding-top: 35px;
  background-image: url("../images/qs-backgr-white.jpg");
  background-position-y: 9%;
  background-position-x: 51%;

  .people-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 210px;
    padding-right: 70px;
    margin-bottom: 25px;

    @include mobile {
      padding: 0;
      padding-right: 20px;
      flex-direction: column;
    }

    .people-heading-left {
      @include comforter(60px);
      color: $primary;

      @include mobile {
        font-size: 45px;
      }
    }

    .people-heading-right {
      display: flex;
      align-items: center;
      gap: 10px;

      @include mobile {
        align-self: flex-end;
      }

      .slider-pagination {
        transition: filter 0.4s;
        &.active {
          filter: brightness(0);
        }
      }
    }
  }

  @include desktop-medium {
    height: auto;
  }
}

.food-price-wrapper {
  padding: 0 30px;
  display: flex;
  align-items: center;
  gap: 30px;

  @include mobile {
    padding: 0 20px;
  }

  .swiper-wrapper {
    width: auto;
  }

  .swiper-slide {
    flex: 1;
    width: auto;
  }

  .food-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .food-image-wrapper {
      position: relative;
      padding-top: 80%;
      overflow: hidden;
      border-radius: 10px;
      background-color: $white;

      .food-image,
      img {
        top: 0;
        bottom: 0;
        left: -9999px;
        right: -9999px;
        position: absolute;
        max-width: 100%;
        height: 100%;
        margin: auto;
        transition: transform 0.8s;
        width: 100%;
        object-fit: cover;
      }
    }

    .food-name {
      padding-left: 20px;
      display: block;
      @include jost-regular(17px);
      color: $primary;

      @include mobile {
        padding-left: 0;
      }
    }
  }

  .swiper-slide-outer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:hover {
      .food-image-wrapper {
        img {
          transform: scale(1.1);
        }
      }
    }

    .swiper-slide-inner {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      @include mobile {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 10px;
      }

      .food-price-grams {
        .food-grams {
          height: 30px;
          min-width: 78px;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 10px;
          background-color: $primary;
          color: $white;
          @include jost-light(13px);
        }
      }

      .food-price {
        height: 30px;
        max-width: 115px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;

        border-radius: 10px;
        background-color: $white;
        @include jost-regular(15px);
        color: $primary;
        transition: max-width 1s;

        @include mobile {
          position: relative;
          padding-left: 10px;
          max-width: 140px;
          gap: 10px;
        }

        .food-cart {
          position: absolute;
          right: 0;
          padding-right: 10px;
          max-width: 192px;
          overflow: hidden;
          display: flex;
          align-items: center;
          gap: 10px;
          border-left: 1px solid $addCart-border;
          padding-left: 10px;
          opacity: 0;
          transition: max-width 0.8s, opacity 0.4s;
          transition-delay: 0.4s, 0s;

          .cart-text {
            font-size: 13px;
          }

          a {
            @include mobile {
              position: absolute;
              left: 0;
              top: -7px;
              width: 100%;
              height: 30px;
              text-indent: -9999px;
            }
          }

          @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
          }
        }

        .price {
          position: relative;
          min-width: fit-content;

          @include mobile {
            flex: 1;
          }
        }
      }

      .menu-food-price {
        @include mobile {
          .food-cart {
            flex: unset;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      &.hover,
      &:hover {
        .food-price {
          cursor: pointer;
          max-width: 100%;
        }
        .food-image,
        .attachment-woocommerce_thumbnail,
        .woocommerce-placeholder {
          transform: scale(1.1);
        }
      }

      &:hover .food-price > .food-cart,
      &.hover .food-price > .food-cart {
        max-width: 100%;
        opacity: 1;
        transition-delay: 0s, 0.5s;
      }
    }
  }
}
