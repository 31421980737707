.register-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 300;
  opacity: 0;
  pointer-events: none;
  transition: all 1.2s;

  .register-container-inner {
    @include mobile {
      top: 2% !important;
    }
  }

  .register-container-inner,
  .login-container-inner,
  .forgotten-pswd-container-inner,
  .guest-location-container-inner,
  .single-popup-container-inner {
    max-width: 790px;
    width: 100%;
    position: fixed;
    right: 0;
    top: 10%;
    z-index: 300;
    transition: opacity 0.6s, transform 1.6s;
    opacity: 0;
    transform: translateX(100%);
    pointer-events: auto;

    @include mobile {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      top: 5%;
      //   height: calc(100% - 10px);
      height: 90%;
    }

    .register-heading {
      color: $white;
      @include comforter(60px);
      margin-bottom: 20px;

      @include mobile {
        text-align: center;
        font-size: 50px;
      }
    }

    .register-form-container {
      position: relative;
      padding-top: 50px;
      padding-bottom: 60px;
      padding-left: 50px;
      background-color: $white;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      @include mobile {
        // height: calc(100% - 100px);
        min-height: 100px;
        max-height: 400px;
        // flex: 1;
        padding-left: 20px;
        padding-bottom: 30px;
      }

      .register-form-container-outer {
        @include mobile {
          overflow-y: auto;
        }
      }

      .register-form-container-inner {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: auto;
        transition: filter 0.8s;

        &.loading {
          filter: blur(2px) opacity(0.5);
          pointer-events: none;
        }
      }

      .social-register-wrapper {
        display: flex;
        gap: 30px;
        margin-bottom: 50px;

        @include mobile {
          flex-direction: column;
        }

        .fb-register,
        .google-register {
          width: 240px;
          height: 35px;
          display: flex;
          align-items: center;
          border: 1px solid $black;
          border-radius: 8px;

          .fb-icon-wrapper,
          .google-icon-wrapper {
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-text {
            height: 25px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            border-left: 1px solid $small-borders;

            @include jost-regular(13px);
          }
        }
      }

      .form-heading {
        @include jost-regular(17px);
        margin-bottom: 25px;
      }

      .form-inputs-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 460px;

        .input-field {
          @include jost-regular(17px);
          padding-bottom: 6px;
          border: none;
          border-bottom: 1px solid $small-borders;
          border-radius: 0;

          &:focus-visible {
            outline: none;
          }
        }

        .input-check-text {
          display: flex;
          align-items: center;
          gap: 5px;

          .text {
            @include jost-light(13px);
          }
        }

        .error {
          display: none;
        }
        .show-error {
          display: block;
        }
      }
    }

    .login-btn-wrapper {
      .login-btn {
        width: 240px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        border: 1px solid $black;
        border-radius: 8px;
        @include jost-medium(13px);
        color: $primary;
      }
    }

    .already-reg-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .unexpected-error:empty {
        order: 2;
        display: none;
      }

      .unexpected-error {
        @include jost-regular(14px);
        color: $red;
      }
    }

    .already-reg {
      display: flex;
      align-items: center;
      gap: 10px;
      order: 3;

      @include jost-regular(13px);

      .login-here {
        text-decoration: underline;
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.show {
    opacity: 1;
    pointer-events: auto;

    .register-container-inner,
    .login-container-inner,
    .forgotten-pswd-container-inner,
    .guest-location-container-inner,
    .single-popup-container-inner {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0%);
    }

    .overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .single-popup-container-inner {
    @include mobile {
      height: auto;
    }
  }
}
.register-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;

  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  background-color: $white;
  border-radius: 6px;

  .close-btn {
    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.border-error {
  outline: none !important;
  border-bottom: 1px solid $red !important;
}

input::placeholder {
  @include jost-light(17px);
}

input:focus::placeholder {
  font-weight: 400;
  color: $black;
}

textarea::placeholder {
  @include jost-light(17px);
}

textarea:focus::placeholder {
  @include jost-regular(17px);
}
