$primary:       #1c1c1c;
$secondary:     #1e1e1e;
$dark:          #1b1b1b;
$border:        #454545;
$small-borders: #cccccc;
$red:           #d40000;
$black:         #000;
$white:         #fff;
$border-green:  #86a738;
$pink:          #f62082;
$border-pink:   #b81560;
$page-active:   #7d9c35;
$button-fb:     #536da5;
$footer-border: #bbbbbb;
$delivery-bg:   #e5e5e5;
$payment-btns:  #adadad;
$show-btn:      #ff8a00;
$progressive:   #369610;
$scrollbar:     #434343;
$addCart-border:#d2d2d2;
$deliveryDisabled:  #e4e4e4;
$hover-bg:      #e9ecef;
$select-color:  #8e8e8e;


$container-padding-x: 0px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1200px
);

$btn-focus-width: 0;
$btn-focus-box-shadow: 0;
$input-focus-width: 0;
$input-focus-box-shadow: 0;

$paragraph-margin-bottom: 0;
$paragraph-padding: 0;

$grid-gutter-width: 0;
$gutters: (
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
);

$border-width: 0;
$border-widths: (
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0
);

.fancybox__thumb:after {
  border-color: $small-borders !important;
}