.profile-nav-marg {
    margin-bottom: 70px !important;
}

.profile-container {
    position: relative;
    margin-bottom: 100px;
    margin-top: 50px;

    @include mobile {
        padding: 0 20px;
    }

    .profile-container-inner {

        max-width: 780px;
        width: 100%;
        margin: 0 auto;

        .profile-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .heading-container {

                text-align: center;
                color: $white;
                @include comforter(60px);

                @include mobile {
                    font-size: 50px;
                }
            }
        }
    }
}

.profile-form,
.woocommerce-MyAccount-content {
    background-color: $white;
    border-radius: 15px;
    padding: 50px 30px 60px;

    @include mobile {
        padding: 30px 20px 40px;
    }

    .profile-password-correction {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .profile-form-wrapper {
      overflow-x: auto;

        .profile-form-heading {
            display: flex;
            flex-direction: column;

            .correction-form {
                display: flex;
                flex-direction: column;
                gap: 50px;
            }

            .input-forms {

                display: flex;
                flex-direction: column;
                gap: 30px;

                .input-forms-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    @include mobile-max-800px {
                        flex-direction: column;
                    }

                    .input {
                        width: 100%;
                        padding-bottom: 6px;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid $small-borders;
                        border-radius: 0;

                        &::placeholder {
                            @include jost-light(17px);
                        }

                        &:focus::placeholder {
                            font-weight: 400;
                        }
                    }

                    .input-cur-pass {
                        max-width: 350px;
                    }
                }

                .input-password-wrapper {
                    @include mobile-max-800px {
                        align-items: flex-start !important;
                    }
                }
            }
        }
    }
}

.woocommerce-EditAccountForm {
  display: flex;
  flex-wrap: wrap;

    fieldset {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 40px 0;
    }

    .input-text {
      width: 100%;
      padding-bottom: 6px;
      border: none;
      outline: none;
      border-bottom: 1px solid $small-borders;
      border-radius: 0;

        &::placeholder {
          @include jost-light(17px);
        }

        &:focus::placeholder {
          font-weight: 400;
        }
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 13px;
    }

    .woocommerce-form-row {
      margin-bottom: 15px;
    }

    > .woocommerce-form-row {

        label {
          display: none;
        }

        @include desktop {
          width: 50%;
          flex: 0 0 50%;

            &:nth-of-type(even) {
              padding-right: 30px;
            }

            &:nth-of-type(odd) {
              padding-left: 30px;
            }
        }

        > span {
          display: block;
          margin-top: 5px;
          font-size: 10px;
        }
    }

    .woocommerce-Button {
      width: 240px;
      height: 35px;
      border: 1px solid #000;
      border-radius: 8px;
      text-align: center;
      text-transform: uppercase;
      font-family: "Jost";
      font-size: 13px;
      font-weight: 400;
      color: #1c1c1c;
      background-color: transparent;
    }
}

.woocommerce-MyAccount-content {

    .woocommerce-notices-wrapper {
      margin-bottom: 0;
    }

    .woocommerce-error {
      list-style-type: none;
    }

    .woocommerce-message {
      color: #ff8a00;
      margin-bottom: 30px;
    }


    .heading,
    legend {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 30px;
      text-transform: uppercase;
      @include jost-regular(22px);
      color: $primary;

        @include mobile {
            font-size: 21px;
        }
    }

    .wcmca_action_button_container,
    #wcmca_add_new_address_button_shipping,
    .wcmca_billing_addresses_title,
    .wcmca_address_container,
    .wcmca_duplicate_address_button,
    .class_action_sparator,
    .wcmca_address_title_checkbox {
      display: none;
    }

    .col-1,
    .col-2 {
      flex: 0 0 100%;
      width: 100%;
    }

    #wcmca_custom_addresses {
      display: flex;
      flex-direction: column;
    }

    .woocommerce-Addresses {
      order: 2;
    }

    #wcmca_add_new_address_button_billing {
      width: 240px;
      height: 35px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-radius: 8px;
      text-transform: uppercase;
      font-family: "Jost";
      font-size: 13px;
      font-weight: 400;
      color: #1c1c1c;
    }
}

