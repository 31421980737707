.profile-form {
  .address-form-heading {
    margin-bottom: 0 !important;
    gap: 15px !important;
  }

  .address-info-settings {

    .address-control-items {
      margin-bottom: 50px;
    }

    .address-control-entry {
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mobile {
        padding-right: 0;
      }

      .address-control-edit {
        transform: translateY(-100%);
        opacity: 0;
        pointer-events: none;
        transition: transform 0.8s, opacity 0.8s;
      }

      .address-control-item {
        height: 51px;
        padding-top: 20px;
        display: flex;
        gap: 30px;

        @include mobile {
          height: auto;
        }

        .address {
          height: 35px;
          flex: 1;
          @include jost-light(17px);
          color: $primary;

          @include mobile {
            height: auto;
          }

          .address-text {
            height: 30px;
            width: 100%;
            border: none;
            outline: none;
            resize: none;
          }
        }
      }
    }
  }

  .new-address-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .new-address-heading {
      padding-bottom: 25px;
      border-bottom: 1px solid $small-borders;
      text-transform: uppercase;
      @include jost-regular(22px);
      color: $primary;
    }

    .new-address-input {
      padding-top: 0;
      padding-right: 20px;
      @include mobile {
        padding-right: 0;
      }
    }
  }

  .btn-center {
    margin: 0 auto;
  }
}

.table-address-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 10px 0;
  padding-right: 20px;
  border-top: 1px solid $small-borders;
  border-bottom: 1px solid $small-borders;

  .heading-text {
    text-transform: uppercase;
    @include jost-regular(13px);
    color: $primary;
  }
}

.address-info {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .address-image-wrapper {
    position: relative;
    top: 4px;
  }

  .text-wrapper {
    padding-right: 45px;
    @include jost-light(14px);
    color: $primary;

    @include mobile {
      padding: 0;
    }
  }
}

.new-address-input {
  height: 51px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 50px;

  @include mobile {
    height: auto;
    gap: 25px;
  }

  .input {
    height: 31px;
    padding-bottom: 6px !important;
    flex: 1;
    resize: none;
    border: none;
    outline: none;
    border-bottom: 1px solid $small-borders;
    border-radius: 0;
    @include jost-regular(17px);

    @include mobile {
      flex: auto;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: unset;
  }

  .select-wrapper {
    width: 100%;
    flex: 1;
    border: none;

    .input {
      width: 100%;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .dropdown-arrow-wrapper {
      right: 5px;
    }

    .select-btn {
      line-height: 24px;
      color: $select-color;
      @include jost-light(17px);
      text-transform: none;
    }

    .select-dropdown {
      font-weight: 400;
    }
  }
}

.control-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  @include mobile {
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.addresses {

    .wcmcam_address_block_title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 5px;
    }

    .wcmca_default_address_badge {
      width: 100%;
      flex: 0 0 100%;
    }

    .wcmca_default_address_badge {
      padding: 0;
      font-size: 12px;
    }
}

.wcmca_address_title {
  font-family: "Jost";
  font-size: 17px;
  font-weight: 300;
  color: #1c1c1c;
  padding-right: 10px;
  margin-right: auto;
}

.wcmca_edit_address_button,
.wcmca_delete_address_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  font-size: 0;
  box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.15);
}

.wcmca_edit_address_button {
  margin-right: 10px;
  background: url('../images/edit-image.png') center no-repeat;
}

.wcmca_delete_address_button {
  background: url('../images/x-image.png') center no-repeat;
}
