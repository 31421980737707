.delivery-time-container { 
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 100px;

    .delivery-inner-container { 
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 45px;

        .delivery-ready-heading { 
            color: $white;
            line-height: 1;
            @include comforter(60px);
        }

        .delivery-wrapper {
            width: 100%;
            height: 600px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: $white;
            border-radius: 15px;

            @include mobile {
                height: auto;
                padding-top: 60px;
                padding-bottom: 110px;
            }

            .delivery-time-heading { 
                padding-bottom: 30px;
                @include jost-regular(30px);
                color: $primary;

                @include mobile { 
                    text-align: center;
                    font-size: 25px;
                }
            }

            .delivery-border {
                width: 180px;
                height: 1px;
                background-color: $small-borders;
                margin-bottom: 30px;
            }
            
            .delivery-image-wrapper {
                position: relative;
                margin-bottom: 30px;

                .plate-wrapper, .clock-image-wrapper { 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .clock-image-wrapper { 
                    position: absolute;
                    top: 25%;
                    left: 25%;
                    transform:translateX(1%);
                }
            }

            .delivery-time {
                padding-bottom: 30px;
                @include mobile {
                    text-align: center;
                }
                .delivery, .time { 
                    @include jost-medium(30px);
                    color: $primary;

                    @include mobile { 
                        font-size: 25px;
                    }
                }
                .time { 
                    color: $red;
                }
            }

            .delivery-info {
                padding-bottom: 30px;
                
                display: flex;
                align-items: center;
                gap: 10px;

                @include mobile { 
                    flex-wrap: wrap;
                    justify-content: center;
                }
               
               .delivery-order { 

                .order, .order-red {
                    @include jost-regular(17px);
                    color: $primary;
                    }

                    .order-red { 
                        color: $red;
                    }
               }
            }

            .home-btn {
                width: 100%;
            }

            .image-one {
                left: -20%;
            }

            .image-two {
                top: 63%;
                left: -17%;
            }

            .image-three {
                top: -6%;
                right: -12%;
            }

            .image-four {
                top: 24%;
                right: -30%;

            }
        }
    }
}

.woocommerce ul.order_details {
    max-width: 980px;
    margin: 0 auto;
    margin-bottom: 30px;
    list-style: none;
    padding: 30px 20px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    @include mobile {
        max-width: calc(100% - 40px);
    }

    li {
        display: flex;
        align-items: center;
        gap: 10px;
        @include jost-regular(17px);
        color: $primary;

        strong {
            white-space: normal;
            font-weight: 500;
            color: $red;
        }
    }
}

.woocommerce form[name="borica_mpi"] {
    max-width: 240px;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    margin: 0 auto; 
    margin-bottom: 100px;

    button {
        @extend .send-message;
    }
}