@keyframes show {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes hide {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes stickyNav { 
    0%      { transform: translateY(-100%); }
    100%    { transform: translateY(0); }
}

@keyframes stickyMsg { 
    0%      { top: 0; }
    100%    { top: 119px; }
}
