.send-message-wrapper {
  max-width: 240px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
}

.send-message {
  text-align: center;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  @include jost-regular(13px);
  color: $primary;
  transition: 1.2s ease;
  border: 1px solid $black;
  border-radius: 8px;

  &:hover {
    background-color: $black;
    color: $white;
  }
}
