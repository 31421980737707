.food-price-wrapper .swiper-slide-outer:hover {
    .button-like {
        transform: translateX(0);
    }
}

.button-like {
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    height: 31px;
    width: 33px;
    transition: transform 0.6s ease;
    background-color: $white;
    cursor: pointer;
    z-index: 1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateX(100%);

    @include mobile {
        transform: translateX(0);
    }

    &:active {
        transition-duration: 0.3s;
  
        .bg::before {
          opacity: 0.25;
        }
      }
}

.heart-icon-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    border-radius: inherit;
    background: $white;
    z-index: 2;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    }

    &:after {
      background: white;
    }

    &:before {
      transition: transform 0.6s ease;
      transform: translateY(15%);
      transform-origin: bottom center;
      background: black;
      filter: blur(1rem);
      opacity: 0.2;
      z-index: -1;
    }
  }

  .heart-icon {
    transition: transform 0.6s ease;
    z-index: 5;

    svg {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 17px;
      height: 14px;

      transition: background-color 0.6s ease, transform 0.6s ease,
        opacity 0.6s ease;

      path {
        fill: $white;
        stroke: $primary;
      }
    }
  }

  .button-like-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    &:checked {
      ~ .heart-icon {
        svg.below {
          transform: scale(1.6);
          opacity: 0;
          path {
            fill: $red;
          }
        }
        svg.front {
          path {
            fill: $red;
          }
        }
      }
    }
  }
