.cart-container {
  position: relative;
  max-width: 1020px;
  margin: 0 auto;
  margin-bottom: 50px;

  @include mobile {
    padding: 0 20px;
  }

  .cart-inner-container {
    .cart-heading {
      margin-bottom: 35px;
      line-height: 1;
      text-align: center;
      color: $white;
      @include comforter(60px);

      @include mobile-max-550px {
        font-size: 50px;
      }
    }
  }

  .cart-wrapper {
    background-color: $white;
    padding: 50px;
    border-radius: 15px;

    .cart-product-wrapper {
      transition: opacity 0.6s;

      &.loading {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    @include mobile-max-1000px {
      padding: 20px;
    }

    .cart-inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;

      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      &:first-of-type {
        padding-top: 20px;
        border-top: 1px solid $small-borders;
      }

      &:last-of-type {
        border-bottom: 1px solid $small-borders;
      }

      .product-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;

        .number {
          @include jost-medium(17px);
        }

        .product-image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 60px;
          max-width: 60px;
          height: 60px;
          overflow: hidden;
          border-radius: 8px;

          .product-image,
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .product-text {
          .text {
            @include jost-regular(17px);
            color: $primary;
          }

          .without-text {
            color: $red;
            @include jost-medium-italic(13px);
          }

          .without {
            color: $primary;
            @include jost-light(13px);
          }
        }
      }

      .product-price {
        display: flex;
        // align-items: center;
        gap: 40px;
        @include mobile {
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

        .price {
          @include jost-medium(17px);
          color: $primary;
        }

        .product-quantity {
          display: flex;
          align-items: center;
          .quantity-number {
            display: flex;
            align-items: center;
            @include jost-medium(13px);

            .quantity,
            .qty {
              text-align: center;
              width: 20px;
              outline: none;
              border: none;
              @include jost-medium(17px);
              color: $primary;
              -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
            }
          }

          .arrow-left-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .arrow-right-wrapper {
            position: relative;
            top: -1px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .code-utensils-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 40px;
      margin-bottom: 30px;

      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .code-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        @include mobile-max-550px {
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
        .input-code {
          width: 230px;
          padding-bottom: 6px;

          border: none;
          outline: none;
          border-bottom: 1px solid $small-borders;
          @include jost-regular(17px);
          border-radius: 0;
        }

        .btn-code {
          width: 180px;
        }
      }

      .utensils-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        @include mobile-max-550px {
          flex-direction: column;
          gap: 20px;
        }

        .utensils-text {
          @include comforter(50px);
          color: $primary;

          @include mobile-max-550px {
            font-size: 40px;
          }
        }

        .utensils-input-wrapper {
          // width: 100px;
          display: flex;
          align-items: center;
          gap: 10px;

          .utensils-input-inner {
            display: flex;
            align-items: center;
            @include jost-medium(13px);

            .input-utensils {
              width: 30px;
              text-align: center;
              border: none;
              outline: none;
              color: $primary;
              background: transparent;
              @include jost-medium(17px);
            }

            .utensils-image-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0.2;
              transition: opacity 0.4s;
              .utensils-image {
                width: 100%;
                height: 100%;
              }

              &.active {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .remove {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;

      box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
      border-radius: 6px;

      .remove-btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .receive-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 25px;

      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .text {
          font-size: 40px;
        }
      }
      .text {
        @include comforter(50px);
        color: $primary;
      }
    }

    .choose-address-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }
      .text {
        position: relative;
        top: -8px;
        align-self: flex-start;
        color: $primary;
        line-height: 1;
        @include comforter(50px);

        @include mobile {
          top: unset;
          font-size: 40px;
        }
      }

      .address-select-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        .select-wrapper {
          border: 1px solid $small-borders;
          border-radius: 8px;
          width: 100%;

          .address-select {
            height: 35px;
            width: 100%;
            color: $primary;
            padding-left: 10px;
            font-weight: 500 !important;
          }
        }
      }

      .address-select-wrapper-outer {
        display: flex;
        flex-direction: column;
        flex: 1;

        .new-address-input {
          padding-top: 0;
        }
      }
    }

    .delivery-form {
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;

      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 30px;
      }

      .delivery-form-inputs {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 420px;
        width: 100%;

        .select-wrapper {
          width: 100%;

          .select-btn {
            width: 100%;
            padding-left: 0;
            color: $select-color;
            font-weight: 300;
            text-transform: capitalize;
          }

          .dropdown-arrow-wrapper {
            right: 0;
          }
        }
      }
    }

    .input {
      padding-bottom: 6px;
      border: none;
      outline: none;
      border-bottom: 1px solid $small-borders;
      @include jost-regular(17px);
      border-radius: 0;
    }

    .input-date {
      position: relative;
      display: flex;

      .res-image-wrapper {
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .date,
      .time {
        padding-left: 0;
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        border-radius: 0;
      }
    }

    .delivery-checkout-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }
      .delivery-checkout-info {
        max-width: 450px;
        width: 100%;

        .comment-field {
          max-width: 420px;
          width: 100%;
          height: 120px;

          border: none;
          outline: none;
          resize: none;
          border-bottom: 1px solid $small-borders;
          @include jost-regular(17px);
          border-radius: 0;
        }
      }

      .delivery-time {
        padding-top: 30px;
        display: flex;
        align-items: center;
        gap: 10px;

        @include mobile-max-1000px {
          padding-bottom: 30px;
        }

        .delivery-time-image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 70px;
          min-width: 70px;
          border-radius: 100%;
          background-color: $delivery-bg;
        }

        .delivery-time-text {
          @include comforter(40px);
          color: $primary;

          @include mobile-max-550px {
            font-size: 30px;
          }

          @include mobile {
            text-align: center;
          }
        }

        .delivery-time-text-red {
          color: $red;
        }
      }

      .checkout-cashback-outer {
        max-width: 410px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

input:focus::placeholder,
input:focus {
  font-weight: 400;
}

textarea:focus::placeholder,
textarea:focus {
  font-weight: 400;
  color: $primary;
}
