.checkout-cashback-container {
    min-height: 130px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    border: 1px solid $small-borders;
    border-radius: 8px;

    @include mobile-max-550px {
        padding: 10px 20px;
        height: auto;
    }

    .checkout-cashback-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkout-cashback-total-text {
            text-transform: uppercase;
            @include jost-medium(15px);
            color: $primary
        }

        .checkout-cashback-total {
            color: $primary;
            @include jost-medium(17px);
        }

        .checkout-cashback-total-bgn {
            color: $primary;
            @include jost-medium(13px);
        }
    }

}


.cashback-use-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .cehckout-cashback-use-text {
      text-transform: uppercase;
      @include jost-medium(15px);
      color: $primary;
  }

  .cashback-use-label {
      display: flex;
      align-items: center;
      gap: 5px;

      .cashback-use {
          width: 66px;
          height: 32px;
          outline: none;
          border: 1px solid $small-borders;
          border-radius: 8px;
          text-align: center;
          color: $red;
          @include jost-medium(17px);

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
          }
      }

      @include jost-medium(13px);
  }
}

.cashback-check-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    @include jost-light(13px);
    color: $primary;
}
