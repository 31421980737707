.qty {
  @extend .input;
}

.cart-container .cart-wrapper .qty {
  padding-bottom: 0;
}

.added_to_cart,
.single_add_to_cart_button {
  @extend .btn-cart;

  &.added {
    display: none !important;
  }
}

.single-product-form .added_to_cart {
  text-transform: uppercase;
  color: #1c1c1c;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 500;
  background: url("../images/cart.png") 10px center no-repeat;
  text-transform: uppercase;
  justify-content: center;
}

.woocommerce-products-header {
  @extend .menu-heading-container;
}

.woocommerce-products-header__title {
  font-size: inherit;
}

.products {
  @extend .menu-row;
}

.woocommerce-LoopProduct-link {
  @extend .food-item-wrapper;
}

.woocommerce-loop-product__title {
  @extend .food-name;
}

.wpcf7-submit,
.btn-code {
  justify-content: center;
  background-color: transparent;
}

// .wpcf7 .wpcf7-spinner {
//   display: none;
// }

.wpcf7 form.wpcf7-form .wpcf7-response-output {
  margin: 0 0 20px 0;
}

.shop_table {
  width: 100%;
}

button[name="update_cart"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

#billing_address_1_field label {
  display: none !important;
}

.woocommerce-billing-fields > h3 {
  display: none;
}

.select2 {
  display: none;
}

.woocommerce-billing-fields__field-wrapper {
  @extend .row;
  margin-left: -40px;
  margin-right: -40px;

  .form-row {
    @extend .col-sm-6;
    margin-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.woocommerce-billing-fields__field-wrapper,
.mfp-content {
  .input-text,
  .select,
  .additional {
    width: 100%;
    padding-bottom: 6px;
    border: none;
    outline: none;
    border-bottom: 1px solid $small-borders;
    @include jost-regular(17px);
    border-radius: 0;
  }
}

textarea.input-text {
  width: 100%;
  height: 120px;
  border: none;
  outline: none;
  resize: none;
  border-bottom: 1px solid $small-borders;
  @include jost-regular(17px);
  border-radius: 0;
}

.woocommerce-input-wrapper {
  display: block;
}

.woocommerce-no-products-found {
  text-align: center;
}

.woocommerce-shipping-destination {
  display: none;
}

.woocommerce-shipping-methods {
  input {
    appearance: none;
    -webkit-appearance: none;
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:not(:checked) {
      + label {
        display: none;
      }
    }
  }
}

.checkout-pricing-wrapper,
.payment_methods {
  margin-bottom: 20px;
}

.woocommerce-terms-and-conditions-wrapper .woocommerce-form__label {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 300;
  color: #1c1c1c;

  a {
    color: #d40000;
    text-decoration: underline;
  }
}

.error-message {
  color: #d40000;
}

.place-order {
  .button {
    max-width: 240px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    width: 100%;
    height: 35px;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #000;
    border-radius: 8px;
    font-family: "Jost";
    font-size: 13px;
    font-weight: 500;
    color: #1c1c1c;
    text-transform: uppercase;
    background-color: transparent;
    transition: 1.2s ease;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.choose-address-wrapper .button.wcmca_add_new_address_button,
#wcmca_save_address_button_billing {
  display: inline-block;
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 37px;
  border-radius: 8px;
  color: $white;
  background-color: #d40000;
  text-transform: uppercase;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 500;
  border: none;
}

.woocommerce-billing-fields .wcmca_loader_image {
  margin: 0 auto 25px;
  clear: both;
  width: 16px;
  height: 16px;
}

.mfp-content {
  .wcmca_form_label {
    display: none;
  }

  .form-row {
    margin-bottom: 20px;
  }
}

.cart-wrapper .fgf-success-message {
  display: none;
}

#lightcase-case {
  top: 100px !important;
  right: 0 !important;
  margin: 0 !important;
  left: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  h3 {
    margin-bottom: 30px;
    font-family: "Comforter";
    font-size: 60px;
    font-weight: 400;
  }
}

div#lightcase-content {
  width: 600px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fgf-product-variations {
  margin-bottom: 15px;
  width: 100%;
  height: 35px;
}

a[class*="lightcase-icon-"].lightcase-icon-close {
  top: 115px;
  width: 25px;
  height: 25px;
  box-shadow: 0 0 7px rgb(0 0 0 / 15%);
  background: url("../images/x-image.png") center no-repeat;
  border-radius: 6px;

  &:before {
    display: none;
  }

  > span {
    display: none;
  }

  @include mobile-640 {
    top: 90px;
    right: 35px;
  }
}

.fgf-gift-products-content {
  width: 305px;

  @include mobile-370 {
    width: 255px;
  }
}

.lightcase-contentInner {
  height: auto !important;
}

.lightcase-inlineWrap {
  max-height: 100% !important;
}

.owl-carousel .owl-item {
  text-align: center;
  overflow: hidden;

  img {
    width: 60px;
    max-width: 100%;
    height: 60px;
    max-height: 150px;
    border: 1px solid #efefef;
    border-radius: 10px;
  }
}

.fgf-owl-carousel-item {
  padding: 15px;
}

.fgf-owl-carousel-items .owl-item span {
  margin-bottom: 0;
}

.fgf-add-manual-gift-product {
  width: 240px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 400;
  color: #1c1c1c;
}

.xoo-el-form-container {
  padding-bottom: 0;
}

.xoo-el-header {
  display: none;
}

.xoo-aff-group input[type="text"],
.xoo-aff-group input[type="password"],
.xoo-aff-group input[type="email"],
.xoo-aff-group input[type="number"],
.xoo-aff-group select,
.xoo-aff-group select + .select2 {
  font-family: "Jost";
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 6px;
  border: none;
  line-height: 1;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  height: 33px;
  box-shadow: none;
}

.xoo-aff-group label {
  font-weight: 400;
}

.xoo-el-form-txt {
  margin-bottom: 30px;
  font-size: 14px;
}

.xoo-aff-group input[type="text"]:focus,
.xoo-aff-group input[type="password"]:focus,
.xoo-aff-group input[type="email"]:focus,
.xoo-aff-group input[type="number"]:focus,
.xoo-aff-group select:focus,
.xoo-aff-group select + .select2:focus {
  background-color: transparent;
  color: #000;
  outline: none;
}

.xoo-el-form-container button.btn.button.xoo-el-action-btn {
  width: 240px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 8px;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 500;
  color: #1c1c1c;
  margin-bottom: 50px;
  transition: 1.2s ease;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.xoo-el-login-btm-fields {
  .xoo-el-lostpw-tgr {
    display: none;
  }
}

#billing_country_field,
#billing_city_field,
#wcmca_billing_country_field,
#wcmca_billing_state_field,
#wcmca_billing_city_field,
.fgf-notice {
  display: none !important;
}

.woocommerce-info {
  background-color: $white !important;
  color: #d40000 !important;
  border-radius: 10px;
  border-left: 0 !important;
}

.woocommerce-notices-wrapper {
  max-width: 1060px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.woocommerce-message {
  background-color: $white !important;
  border-left: 0 !important;
  color: #0f834d !important;
  border-radius: 10px;

  a:hover {
    color: #0f834d !important;
  }
}

.woocommerce-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .page-numbers {
    display: block;
    padding: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .current,
  a.page-numbers:hover {
    color: $orange;
  }
}

.food-price-wrapper .swiper-slide-outer {

  .added_to_cart {
    display: none;
  }
}

.food-price-wrapper .swiper-slide-outer .swiper-slide-inner .food-price .food-cart .cart-text,
.product_type_simple.add_to_cart_button {
  line-height: 22px;

    &.loading {
      color: #fff;
      background: url(../images/loader.svg) no-repeat center / contain;
    }
}

.ti-widget {
  position: relative;
  z-index: 5;
}

.rating-container .ti-widget.ti-goog .ti-profile-img img {
  width: 100px !important;
  height: 100px !important;
}

.single_product_comment {
  margin-top: 20px;
  padding-left: 40px;

  textarea {
    width: 100%;
    height: 60px;
    border: 1px solid #fff;
    background: transparent;
    padding: 10px;
    color: #fff;
    border-radius: 0.375rem;

    &::placeholder {
      color: #fff;
    }
  }
}

.cart-wrapper .woocommerce-error {
  border-radius: 8px;
}

.single-product-add {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-top: 10px;
  font-style: 14px;
  color: #fff;
  border-top: 1px solid rgba(#fff, 0.3);

  span {
    margin-left: auto;
    margin-right: 10px;
  }
}

.single-product-add-qty {
  width: 50px;
  height: 35px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 8px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

select {
  cursor: pointer;
}

.single-product-attributes {
  position: relative;
  z-index: 5;
}

.single-product-attributes-dropdown {
  position: relative;
  z-index: 5;

    @include desktop {
      width: 50%;
    }

    .check-inputs-adds {
      position: absolute;
      top: calc(100% + 10px);
      display: block;
      overflow: auto;
      background-color: #fff;
      padding-right: 5px;
      width: 100%;
      border: 1px solid #cccccc;
      border-radius: 8px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.8s;
      z-index: 10;
      font-family: "Jost";
      font-size: 15px;
      font-weight: 400;
      max-height: 200px;

        .check-input {
          padding: 10px;
          color: #000;

            &:not(:last-child) {
              border-bottom: 1px solid #cccccc;
            }
        }
    }

    &.active {

        .check-inputs-adds {
          opacity: 1;
          pointer-events: auto;
        }
    }
}

.single-product-attributes-btn {
  text-align: left;
  width: 100%;
  height: 35px;
  border: 1px solid #fff;
  background: transparent url('../images/arrow-dropdown.png') calc(100% - 10px) center no-repeat;
  border-radius: 8px;
  min-width: 240px;
  color: #fff;
  font-family: "Jost";
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 50px;
  padding-right: 30px;
}

.my-orders-table {
  width: 100%;
  font-size: 13px;
  // table-layout: fixed;

    td, th {
      padding: 6px;
      border: 1px solid #010101;

        &:nth-child(2) {
          width: 100%;
        }
    }

    div {
      font-size: 10px;

        &:not(:last-child) {
          padding-bottom: 5px;
          margin-bottom: 5px;
          border-bottom: 1px solid #eee;
        }

        div {
          margin-top: 5px;
        }

        strong {
          color: #d40000;
        }

        a {
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 5px;
          text-decoration: underline;

            &:hover {
              color: #d40000;
            }
        }
    }

    p {
      display: inline;
    }
}

.lost_reset_password {
  padding: 50px 0;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;

    > p {

        &:first-child {
          margin-bottom: 30px;
          font-size: 20px;
          text-align: center;
        }
    }

    .form-row {
      margin-bottom: 15px;
      font-size: 14px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      height: 45px;
      color: #fff;
      font-size: 16px;
      background: transparent;
      border: none;
      border-bottom: 1px solid #fff;
    }

    button {
      max-width: 240px;
      padding-left: 10px;
      padding-right: 10px;
      display: block;
      margin-top: 30px;
      width: 100%;
      height: 35px;
      align-items: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #fff;
      border-radius: 8px;
      font-family: "Jost";
      font-size: 13px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      background-color: transparent;
      transition: 1.2s ease;

        &:hover {
          color: #000;
          background-color: #fff;
        }
    }
}


.woocommerce-order-received {

    .woocommerce-order-details,
    .woocommerce-customer-details {
      display: none;
    }
}

.cashback-heading {
  margin-top: 30px;
}
