.nav-container {
    z-index: 2000;
    top: 0;
    left: 0;

    .nav-row {

        @include mobile {
            justify-content: center;
            align-items: center;
        }
        .mobile-col {
            @include mobile {

                max-width: 60px;
                padding: 0;
            }

            .mobile-nav-right {
                align-items: flex-end;
                gap: 15px;

                .cart-wrapper {
                    position: relative;

                    .cart-number {
                        position: absolute;
                        color: $white;
                        top: -10px;
                        right: -5px;
                        @include jost-light(11px);
                    }
                }
            }

            .mobile-nav-left {
                gap: 15px;
            }
        }

        .mobile-col-center {
            @include mobile {
                max-width: 140px;
                padding: 0;
            }

            .mobile-center {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 140px;
                height: 70px;

                .mobile-log {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .hamburger-menu-wrapper {
        padding-top: 20px;
        height: 100vh;
        background-image: url("../images/bg.png");
        width: 100%;
        position: fixed;
        gap: 50px;
        z-index: 11;
        left: 0;
        top: 0;
        overflow: hidden;
        transition: opacity 0.8s;

        // opacity: 0;
        // pointer-events: none;

        .hamburger-col {
            position: relative;
            right: 2px;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .language-menu {

                padding: 0;
                border-left: none;
            }

            .logo {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 140px;
                height: 70px;

                .mobile-log {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .contact-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

        .nav-left {
            align-items: center;
            gap: 20px;
        }

        .close-nav {
            display: flex;
        }

        &.active {
            opacity: 1;
            pointer-events: auto;
        }

        &:not(.active) {
            opacity: 0;
            pointer-events: none;
        }
    }
}
