.terms-nav-marg { 
    margin-bottom: 90px !important;
}

.terms-container {
    padding: 0 10px;
    max-width: 1200px;
    margin-bottom: 100px;

    @include mobile {
        padding: 0 20px;
        text-align: center;
    }

    .terms-heading {
        padding-bottom: 25px;
        border-bottom: 1px solid $border;
        color: $white;
        text-transform: uppercase;
        @include jost-semiBold(36px);

        @include mobile { 
            font-size: 25px;
            text-align: center;
        }
    }

    .terms-text-wrapper {
        padding-top: 30px;
        color: $white;
        line-height: 1;
        @include jost-light(16px);

        p {
            padding-bottom: 25px;

            &:first-of-type {
                text-transform: uppercase;
            }
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}