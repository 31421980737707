.favorites {
    &-products {
        display: flex;
        align-items: center;
        gap: 3px;

        @include jost-regular(13px);
    }

    &-arrows {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    &-item {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 0 !important;

        img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            object-fit: cover;
        }        
    }

    &-text {
        @include jost-medium(17px);
    }

    &-remove {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        min-width: 25px;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        margin-left: auto;
    }

    &-body-item {
        display: flex;
        align-items: center;
    }

    &-text {
        color: $black;
        @include jost-medium(17px);
    }
    
    &-item-count {
        @include jost-medium(17px);
        color: $black;
        padding-right: 25px;
    }

}

.table-body tr {
    .favorites-body-item {
        padding-bottom: 15px !important;
        border-bottom: 1px solid $small-borders ;
    }
}

.table-body tr {
    .favorites-body-item:last-of-type {
        border-bottom: 0 ;
    }
}

.my-orders-table tbody tr:first-of-type .favorites-body-item {
    padding-right: 0 !important;
}

.my-orders-table tbody tr:first-of-type .favorites-body-item:first-of-type {
    padding-top: 30px !important;
}

.my-orders-table tbody tr:first-of-type .favorites-body-item:not(:first-of-type) {
    padding-top: 15px;
}