.my-orders-table td, .my-orders-table th {
    padding: 0;
}

.my-orders-table thead th {
    padding-top: 10px;
}

.my-orders-table tbody tr {
    &:first-of-type {
        td {
            padding: 30px 15px 0 0;
        }
    }

    td {
        padding: 15px 15px 0 0;

        &:last-of-type {
            padding-right: 0;
        }
    }
}

.my-orders-table th {
    @include jost-regular(13px);
}

.my-orders-table td {
    &:first-of-type {
        @include jost-medium(17px);
        color: $red;
    }
}

.my-orders-table thead tr {
    border-top: 1px solid #eee;
}

.my-orders-table tbody tr:not(:first-child) {
    border-top: 1px solid #eee;
}

.my-orders-table time, .my-orders-table .table-body-item, .my-orders-table .woocommerce-Price-amount.amount {
    @include jost-light(17px);
}

.my-orders-table td .food-cart a {
    @include jost-medium(17px);
}

.my-orders-table div a {
    @include jost-medium(17px);
    text-decoration: none;
    margin-bottom: 0;
    display: block;
}

.my-orders-table div strong {
    @include jost-medium(15px);
}

.my-orders-table p {
    @include jost-light(15px);
}

.my-orders-table div:not(:last-child) {
    padding-bottom: 0;
    margin-bottom: 15px;
    border-bottom: 0;
    line-height: 1;
}

.my-orders-table div div {
    margin-top: 10px;
}

.woocommerce-MyAccount-content .heading, .woocommerce-MyAccount-content legend {
    margin-bottom: 20px;
}

.my-orders-table tr td {
    padding-bottom: 15px !important;
}

.my-orders-table tr:last-of-type td {
    padding-bottom: 0 !important;
}

.used-cashback-heading tr:last-of-type td {
    padding-bottom: 0 !important;
}

.used-cashback-heading {
    margin-bottom: 0 !important;
}


