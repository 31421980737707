.index-nav-marg {
  margin-bottom: 40px !important;
}

.how-order-container {
  .how-order-row {
    .how-col {
      .index-how-col {
        @include mobile {
          margin-top: 269px;
        }

        .how-col-text {
          max-width: 751px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          flex: 1;

          @include mobile {
            text-align: center;
            margin-left: 0;
          }

          .check-image-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            @include mobile {
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .index-abs-image {
    left: 40px;

    @include mobile {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.whole-menu-btn-wrapper {
  margin-bottom: 100px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;

  @include mobile {
    margin-bottom: 40px;
    padding: 0 20px;
  }

  .whole-menu-btn {
    line-height: 1;
    background-image: url("../images/qs-backgr-white-small.jpg");
    background-position-x: -5px;
    max-width: 380px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-transform: uppercase;
    @include jost-regular(16px);
    color: $primary;
  }
}
