.how-order-nav-marg {
  margin-bottom: 90px !important;
}

.how-heading-container {
  margin-bottom: 110px;

  @include mobile {
    margin-bottom: 50px;
  }
  .text {
    padding-top: 30px;
    color: $white;
    @include jost-light(16px);
  }
}

.how-order-container {
  position: relative;

  @include mobile {
    padding: 0 20px;
  }

  &:last-of-type {
    @include mobile {
      margin-bottom: 100px !important;
    }
  }

  .how-order-row {
    row-gap: 30px;

    @include mobile {
      row-gap: 120px;
    }

    .how-col {
      .how-heading-bg {
        height: 90px;
        background-image: url("../images/how-to-bg.png");
        background-size: cover;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include mobile {
          justify-content: center;
          padding-left: 0;
        }

        .index-terms-heading {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          max-width: 741px;
          width: 100%;
        }

        .about-heading {
          max-width: unset;
        }

        .how-heading-wrapper {
          height: 50px;
          display: flex;
          align-items: center;
          padding-left: 15px;
          border-left: 1px solid $black;
          max-width: 791px;
          width: 100%;

          text-transform: uppercase;
          @include jost-regular(22px);
          color: $primary;

          @include mobile {
            padding: 0 10px;
            border-left: none;
            font-size: 17px;
            justify-content: center;

            .heading {
              text-align: center;
            }
          }
        }
      }

      .how-col-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        @include mobile {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .how-col-image-wrapper {
          margin-top: 20px;
          height: 390px;
          width: 380px;

          background-color: grey;
          border: 1px solid $white;
          border-radius: 15px;
          overflow: hidden;

          @include mobile {
            height: 200px;
            width: 200px;
            position: relative;
            left: 40px;
          }

          .how-col-image {
            height: 100%;
            width: 100%;
            display: flex;
            object-fit: cover;
          }
        }

        .about-image {
          z-index: 1;
        }

        .how-col-text {
          display: flex;
          flex-direction: column;
          gap: 25px;
          flex: 1;
          max-width: 580px;
          color: $white;
          @include jost-light(16px);

          @include mobile {
            max-width: unset;
            text-align: center;
          }
        }

        .about-text {
          display: flex;
          flex-direction: column;
          gap: 25px;

          @include mobile {
            text-align: center;
          }

          .learn-more-btn-wrapper {
            align-self: flex-start;

            @include mobile {
              align-self: center;
            }
            .learn-more-btn {
              border: 1px solid $white;
              border-radius: 8px;
              padding: 10px 30px;
              line-height: 1;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              //   border-radius: inherit;
              text-transform: uppercase;
              color: $white;
              @include jost-regular(13px);
              transition: 1.2s;

              &:hover {
                color: $black;
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }

  .how-image-abs {
    height: 389px;
    max-width: 380px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-15%);
    border: 1px solid $white;
    border-radius: 15px;
    overflow: hidden;

    @include mobile {
      height: 200px;
      max-width: 200px;
      left: calc(50% - 140px);
      top: 135px;
    }

    .how-image {
      height: 100%;
      width: 100%;
      display: flex;
      object-fit: cover;
    }
  }
}

.secondary-slider-container + .how-order-container {
  .how-image-abs {
    max-width: 310px;
    height: 319px;

    @include mobile {
      max-width: 280px;
    }
  }

  .index-terms-heading {
    max-width: unset !important;
  }

  .how-heading-wrapper {
    max-width: 820px !important;
  }

  .how-col-text {
    max-width: 830px !important;
  }
}
