.contact-nav-marg {
  margin-bottom: 70px !important;
}

.contact-us-heading {
  @include comforter(60px);
}

.contact-us-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 60px;

  @include mobile-max-900px {
    padding: 0 20px;
    flex-direction: column;
  }
  .contact-form-wrapper {
    position: relative;
    top: -12px;
    width: 100%;
    .contact-us-container-wrapper {
      width: 100%;

      .contact-us-container-inner {
        padding-left: 50px;
        padding-right: 20px;
        min-height: 530px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $white;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        @include mobile-max-900px {
          border-radius: 15px;
          justify-content: center;
          align-items: center;
        }

        @include mobile {
          padding: 0 20px;
        }

        .inputs-container {
          display: flex;
          flex-direction: column;
          gap: 25px;

          @include mobile-max-900px {
            width: 100%;
            align-items: center;
          }

          .input-inner {
            width: 100%;
            max-width: 460px;
            .input-select,
            .input,
            .text-input {
              padding-bottom: 6px;
              width: 100%;
              border: none;
              border-bottom: 1px solid $small-borders;
              resize: none;
              outline: none !important;
              border-radius: 0;

              @include jost-regular(17px);
            }

            .text-input {
              height: 115px;
            }

            .select-contact-wrapper {
              width: 100%;

              .select-contact {
                width: 100%;
              }

              .select-contact {
                padding-left: 0;
                padding-bottom: 6px;
                border: none;
                border-bottom: 1px solid $small-borders;
                border-radius: 0;
                color: $select-color;
                @include jost-light(17px);
                text-transform: capitalize;
              }

              .dropdown-arrow-wrapper {
                top: 10px;
              }
            }
          }

          .border-error {
            outline: none !important;
            border-bottom: 1px solid $red !important;
          }

          .error {
            display: none;
          }
          .show-error {
            display: block;
          }
        }
      }
    }
  }
  .contact-us-heading {
    max-width: 780px;
    width: 100%;
    color: $white;

    @include mobile {
      text-align: center;
    }
  }

  .pizza-image-wrapper {
    max-width: 780px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    bottom: -34%;
    right: 9%;

    .pizza-image {
      width: 100%;
    }
  }

  .map-container {
    width: 100%;
    height: 555px;
    background: $white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    @include mobile-max-900px {
      border-radius: 15px;
    }
  }
}

.contact-btns-container {
  margin-bottom: 90px;

  @include mobile-max-800px {
    padding: 0;
  }

  .btns-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include mobile-max-1000px {
      padding: 0 20px;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
    }

    @include mobile-max-800px {
      grid-template-columns: 1fr;
    }

    .btn-wrapper-outer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .btn-wrapper-inner {
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-color: $white;

        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .about-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 90px;
            border: 1px solid $small-borders;
            border-radius: 100%;

            .btn-image-wrapper {
              position: relative;
              width: 50px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;

              .btn-image {
                position: absolute;
                transition: 0.4s;
              }
            }
          }

          &:hover .btn-image {
            filter: brightness(1);
          }
          &:not(:hover) .btn-image {
            filter: brightness(0);
          }
        }
      }

      .text {
        display: block;
        text-align: center;
        @include jost-regular(17px);
        color: $white;
      }
    }
  }
}
