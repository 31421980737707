/* jost-300 - latin_cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/jost-v13-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-300.svg#Jost') format('svg'); /* Legacy iOS */
  }
  /* jost-regular - latin_cyrillic */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/jost-v13-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-regular.svg#Jost') format('svg'); /* Legacy iOS */
  }
  /* jost-500 - latin_cyrillic */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/jost-v13-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-500.svg#Jost') format('svg'); /* Legacy iOS */
  }
  /* jost-600 - latin_cyrillic */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/jost-v13-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-600.svg#Jost') format('svg'); /* Legacy iOS */
  }
  /* jost-300italic - latin_cyrillic */
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/jost-v13-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-300italic.svg#Jost') format('svg'); /* Legacy iOS */
  }
  /* jost-italic - latin_cyrillic */
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/jost-v13-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/jost-v13-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/jost-v13-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/jost-v13-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/jost-v13-latin_cyrillic-italic.svg#Jost') format('svg'); /* Legacy iOS */
  }
  
  /* comforter-regular - latin_cyrillic */
  @font-face {
    font-family: 'Comforter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/comforter-v5-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/comforter-v5-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/comforter-v5-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/comforter-v5-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/comforter-v5-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/comforter-v5-latin_cyrillic-regular.svg#Comforter') format('svg'); /* Legacy iOS */
  }