html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 9px;
}


::-webkit-scrollbar-track {
  background: #cccccc;
}


::-webkit-scrollbar-thumb {
  background: #272727;
}


::-webkit-scrollbar-thumb:hover {
  background: #272727;
}

.row {
    margin-left: 0;
    margin-right: 0;
}
