.container-outer {
  position: relative;
  width: 100%;
  height: 100%;

  .animated-images-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .animated-image {
    position: absolute;
  }

  .image-one {
    top: 20px;
    left: -190px;
  }

  .image-two {
    top: 380px;
    left: -190px;
  }

  .image-three {
    top: -40px;
    right: -120px;
  }

  .image-four {
    top: 160px;
    right: -250px;
  }
}

.reservation-container {
  max-width: 820px;
  padding: 0 20px;
  margin-bottom: 150px;

  @include mobile {
    margin-bottom: 100px;
  }

  .reservation-heading-wrapper {
    margin-bottom: 40px;
    text-align: center;
    @include comforter(60px);
    color: $white;

    @include mobile {
      font-size: 45px;
    }
  }

  .reservation-form {
    max-width: 580px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity 0.4s, transform 0.8s;

    &.active {
      opacity: 1;
      transform: translateX(0) !important;
      position: initial;
      pointer-events: auto;
    }

    .reservation-one-heading-wrapper {
      text-align: center;
      @include jost-regular(17px);
      line-height: 17px;
      color: $primary;
    }

    .reservation-inputs-wrapper {
      max-width: 420px;
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;

      @include mobile {
        padding: 0;
      }

      .input-label {
        position: relative;

        .res-image-wrapper {
          cursor: pointer;
          position: absolute;
          bottom: 8px;
          right: 0;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .input,
      .select-wrapper {
        width: 100%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: 0;

        .select-btn {
          color: $select-color;
        }
      }

      .input {
        @include jost-regular(17px);
        padding-top: 0;
        padding-left: 0;
        border-bottom: 1px solid $small-borders;
        padding-bottom: 6px;
        color: $black;

        &:focus::placeholder,
        &[type="text"]:focus {
          font-weight: 400;
        }
      }

      .input-date {
        cursor: pointer;
      }

      .select-wrapper {
        .dropdown-arrow-wrapper {
          right: 0;
        }

        .select-btn {
          text-transform: none;
          @include jost-light(17px);
        }
      }

      .input-more-info {
        height: 100px;
        resize: none;
      }

      .input::placeholder {
        @include jost-light(17px);
      }

      .res-btn-next {
        align-self: center;
      }
    }

    &-wrapper {
      max-width: 780px;
      padding: 50px 0;
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: $white;
      border-radius: 15px;
      position: relative;
      overflow: hidden;

      @include mobile {
        padding: 50px 20px;
      }
    }

    &-one {
      transform: translateX(-100%);
    }

    &-two {
      transform: translateX(100%);
    }

    .reservation-notice {
      text-align: center;
      max-width: 360px;
      @include jost-regular(17px);
      line-height: 17px;
      color: $red;
    }
  }

  .reservation-form-two {
    .reservation-one-heading-wrapper {
      display: flex;
      gap: 10px;

      .info-image-wrapper {
        position: relative;
        top: 5px;
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
      }

      .info-text {
        text-align: start;
        @include jost-light(14px);
      }
    }

    .res-btn-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .button-submit {
        justify-content: center;
        text-transform: uppercase;
        background-color: transparent;
        @include jost-regular(13px);
      }
    }
  }
}
