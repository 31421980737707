.overlay {
    position: fixed;
    top: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.6s;
    pointer-events: none;

    background: rgba(0, 0, 0, 0.3);
    
    @include mobile {
        background: rgba(0, 0, 0, 0.8);
    }

    &.show { 
        opacity: 1;
        pointer-events: auto;
    }
}