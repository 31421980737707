@import "config";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/swiper/swiper-bundle";
@import "../../node_modules/@fancyapps/ui/dist/fancybox";
@import "../../node_modules/flatpickr/dist/flatpickr";
@import "fonts";

@import "main";
@import "keyframes";

@import "./mixins/mixins";
@import "./mixins/btn";

@import "header";
@import "mobile-header";
@import "footer";
@import "404";
@import "terms-conditions";
@import "how-to-order";
@import "register";
@import "login";
@import "front";
@import "about-us";
@import "menu";
@import "contact";
@import "profile";
@import "address";
@import "cashback";
@import "promo-code";
@import "single-product";
@import "delivery-time";
@import "cart";
@import "./components/single-product-popup";
@import "./components/delivery-time";
@import "./components/checkout-cashback";
@import "./components/checkout-delivery";
@import "./components/cart-delivery-btns";
@import "./components/reservation";
@import "./components/profile-nav";
@import "./components/people-like";
@import "./components/index-main-slider";
@import "./components/index-secondary-slider";
@import "./components/select";
@import "./components/empty-cart";
@import "./components/overlay";
@import "./components/select-radio";
@import "./components/favorites";
@import "./components/like-button";

@import "animatable";

@import "woocommerce";

@import "./components/my-orders";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-image: url("../images/black-backgr-gradient.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: black;
  overflow-x: hidden;

  @include mobile {
    background-size: auto;
    background-position-y: 119px;
    background-size: 100% 100%;
  }

  &.sticky-nav {
    padding-top: 119px;
  }
}

.disable-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.measeure {
  height: var(--height);
}

html.disable-scroll,
html.disable-scroll body {
  height: calc(var(--window-inner-height) - 1px);
  overflow: hidden;
}

// MODALS MARGINS FIX

.xoo-el-form-container.xoo-el-form-inline {
  margin: 0 auto;
}
.xoo-el-section {
  padding-left: 0;
}

.menu-container .menu-row,
.menu-container .products {
  justify-content: start;

  @include mobile {
    justify-content: center;
  }
}

.single_product_comment {
  padding-left: 20px;

  textarea {
    border-radius: 15px;
    resize: none;
  }
}

.woocommerce {
  .cart-container {
    margin-top: 50px;
  }
}

.closed-msg {
  body.sticky-msg & {
    width: 100%;
    z-index: 10;
    position: fixed;
    animation-name: stickyMsg;
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-duration: 1.6s;
  }
}

.fgf-owl-carousel-items .owl-item .fgf-owl-carousel-item {
  border: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 15px;
  justify-content: center;
}

.fgf-owl-carousel-items h5 {
  @include jost-regular(17px);
}

.fgf-owl-carousel-items .owl-nav button.owl-prev,
.fgf-owl-carousel-items .owl-nav button.owl-next {
  background: none !important;
  padding: 0 !important;
  border: 0 !important;

  text-indent: 100%;
  overflow: hidden;
  width: 8px;
  height: 9px;
  top: 35px;
  transform: translateY(0);
}

.fgf-owl-carousel-items .owl-nav [class*=owl-]:hover {
  background: none;
  color: $black;
}

.fgf-owl-carousel-items .owl-nav button.owl-prev {
  background-image: url("https://quattrostagioni.bg/wp-content/webp-express/webp-images/themes/quattro-stagioni/assets/images/arrow-left-polygon.png.webp") !important;
  background-repeat: no-repeat !important;
}

.fgf-owl-carousel-items .owl-nav button.owl-next {
  background-image: url("https://quattrostagioni.bg/wp-content/webp-express/webp-images/themes/quattro-stagioni/assets/images/arrow-right-polygon.png.webp") !important;
  background-repeat: no-repeat !important;
}

.fgf-popup-gift-products-wrapper {
  @include mobile-640 {
    padding: 30px 30px 30px 20px;
    max-height: 450px;
  }
}

#lightcase-overlay {
  @include mobile-640 {
    opacity: 0.5 !important;
  }
}

html:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
  padding: 40px 0 30px 50px;
}

#lightcase-case {
  text-shadow: none;
}

#wcmca_form_popup_container_shipping, #wcmca_form_popup_container_billing {
  border-radius: 10px;
}

.wcmca_save_address_button_container {
  text-align: center;
}

#wcmca_save_address_button_shipping, #wcmca_save_address_button_billing {
  width: 180px;
}

.woocommerce-MyAccount-content #wcmca_add_new_address_button_billing {
  transition: 1.2s ease;
  &:hover {
    background-color: $black;
    color: $white;
  }
}

#wcmca_close_address_form_button_shipping, #wcmca_close_address_form_button_billing {
  text-indent: -999999px;
    background-image: url(https://quattrostagioni.bg/wp-content/webp-express/webp-images/themes/quattro-stagioni/assets/images/x-image.png.webp);
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    right: 15px;
    top: 15px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    padding: 12.5px;
    border-radius: 6px;
    background-position-x: center;
    background-position: center;
    opacity: 1;
}

.wcmca_default_checkobx_label {
  cursor: pointer;
  font-weight: 500 !important;
}

#wcmca_address_form_billing {
  max-width: 355px;
}

#wcmca_form_popup_container_shipping .wcmca_input_field, #wcmca_form_popup_container_billing .wcmca_input_field {
  &::placeholder {
    @include jost-light(17px);
  }
  &:focus::placeholder {
    font-weight: 400;
    color: $black;
  }
}

.my-orders-table td, .my-orders-table th {
  border: 0;
  vertical-align: baseline;
}

.register-container, .login-container {
  .login-container-inner, .register-container-inner{
    @include desktop {
      display: block;
      max-height: 100%;
      overflow: auto;
    }
  }
}