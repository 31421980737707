.single-product-container {
  margin-bottom: 120px;

  @include mobile {
    margin-bottom: 100px;
  }
  .single-product-form {
    position: relative;
    display: flex;
    justify-content: flex-end;

    @include mobile {
      padding: 0 20px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .single-product {
      max-width: 710px;
      width: 100%;

      @include desktop {
        min-height: 430px;
        display: flex;
        flex-direction: column;
      }

      .single-product-heading-wrapper {
        padding-left: 40px;
        padding-bottom: 20px;

        @include desktop {
          margin-bottom: auto;
        }

        @include mobile {
          padding-left: 0;
        }

        .single-product-heading {
          padding-bottom: 20px;
          text-transform: uppercase;
          @include jost-medium(30px);
          color: $white;

          @include mobile {
            text-align: center;
          }
        }

        .products-text-color {
          @include jost-medium-italic(16px);
          color: $red;
        }

        .products-text {
          @include jost-light(16px);
          color: $white;
        }
      }

      .single-product-selection {
        .single-product-heading-wrapper {
          @include desktop {
            min-height: 0;
          }
        }
        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          padding-left: 40px;
          padding-bottom: 30px;

          @include mobile {
            padding-left: 0;
            flex-direction: column;
            align-items: center;
          }

          .select-wrapper {
            width: 50%;
            border: 1px solid $white;
            border-radius: 8px;

            @include mobile {
              width: 100%;
            }

            .select-btn {
              height: 35px;
              padding: 0;
              padding-left: 50px;
              padding-right: 30px;
              min-width: 240px;
              width: 100%;
              color: $white !important;
              @include jost-medium(13px !important);
            }
          }
        }
      }

      .product-price {
        height: 90px;
        background-image: url("../images/footer-bg.png");
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;

        @include mobile {
          padding: 20px 10px;
          height: auto;
          border-radius: 15px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding-left: 40px;
          padding-right: 30px;

          @include mobile {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }

          .product-price-item {
            max-width: 380px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            @include mobile {
              justify-content: center;
              flex-wrap: wrap;
            }
          }

          .price,
          .grams {
            min-width: 75px;
            color: $primary;
            @include jost-medium(16px);
          }

          .grams {
            text-align: end;
          }

          .price {
            min-width: 75px;
          }

          .input-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 40px;

            .slash {
              height: 50px;
              width: 1px;
              transform: rotate(15deg);
              background-color: $black;
            }

            .input {
              text-align: center;
              width: 50px;
              height: 35px;
              outline: none;
              border: 1px solid $black;
              border-radius: 8px;
              @include jost-medium(18px);

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }

            .arrow-left-wrapper {
              position: absolute;
              left: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .arrow-right-wrapper {
              position: absolute;
              right: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .cart-btn-wrapper {
            width: 240px;
            height: 35px;

            .btn-cart {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              gap: 30px;
              padding-left: 10px;

              border: 1px solid $black;
              border-radius: 8px;
              background-color: transparent;

              .cart-image-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .text {
                text-transform: uppercase;
                color: $primary;
                @include jost-medium(13px);
              }
            }
          }
        }
      }
    }
    .product-image-wrapper {
      position: absolute;
      left: 10px;
      top: -20px;
      max-width: 480px;
      width: 100%;
      height: 460px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $white;
      border-radius: 10px;
      overflow: hidden;
      background-color: $white;

      img {
        max-width: 100%;
        height: auto;
      }

      @include mobile {
        height: auto;
        position: relative;
        bottom: 0;
        left: 0;
      }

      .product-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .promo {
        position: absolute;
        top: 30px;
        left: 0;
        width: 145px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        color: $red;
        background-color: $white;
        @include jost-regular(15px);
      }

      .button-like {
        top: 30px;
        transform: translateX(0);

        .heart-icon svg {
          top: 10px;
        }
      } 
    }
  }

  .without-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .check-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .check-input {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $white;
      @include jost-light(14px);

      .input {
        order: -1;
      }
    }
  }

  .check-inputs-adds {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .check-input {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $white;
      @include jost-light(14px);

      .input {
        order: -1;
      }
    }
  }

  .without-text {
    color: $white;
    @include comforter(50px);
  }
}

.single-product-text {
  @include jost-light(16px);
  color: $white;

  @include mobile {
    text-align: center;
  }
}

.combine {
  margin-bottom: 80px;
}

.text-load {
  display: none;
}

.loading {
  .text {
    display: none;
  }

  .text-load {
    display: inline;
  }
}
