.about-menu-marg {
  margin-bottom: 60px !important;
}

.menu-container-outer {
  margin-bottom: 50px;
  @include mobile {
    padding: 0 20px;
  }
}

.menu-container {
  background-image: url(../images/qs-backgr-white.jpg);
  margin-bottom: 50px;
  padding: 35px 30px 50px 30px;
  background-attachment: fixed;

  @include mobile {
    padding: 20px 20px 40px 20px;
  }

  .menu-heading-container {
    max-width: unset;
    padding-left: 210px;
    @include comforter(60px);
    color: $primary;
    margin-bottom: 20px;

    @include mobile {
      padding-left: 0;
      text-align: center;
      font-size: 45px;
    }
  }

  .menu-row {
    margin-bottom: 30px;
    display: grid;
    // grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 275px));
    justify-content: center;
    padding-left: 0;

    // @media (max-width: 1680px) {
    //   grid-template-columns: repeat(4, 1fr);
    // }

    // @media (max-width: 1260px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }

    // @media (max-width: 800px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    // @media (max-width: 600px) {
    //   grid-template-columns: 1fr;
    // }

    .food-item-wrapper {
      position: relative;
      width: 100%;

      .promo-banner {
        position: absolute;
        top: 20px;
        left: 2px;
        height: 30px;
        width: 158px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $red;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        @include jost-light(13px);
        color: $white;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 20px;
    }
  }
}
