@keyframes steam {
  0% {
    top: 100px;
    opacity: 0.075;
    visibility: visible;
  }
  20% {
    top: 80px;
    opacity: 0.2;
  }
  40% {
    top: 60px;
    opacity: 0.4;
  }
  60% {
    top: 40px;
    opacity: 0.2;
  }
  80% {
    top: 20px;
    opacity: 0.075;
  }
  100% {
    top: 0px;
    opacity: 0;
    visibility: hidden;
  }
}

.main-container {
  margin-bottom: 30px;
  padding: 0;

  .swiper {
    margin-bottom: 70px;
    overflow: visible;
  }

  .slide {
    height: 460px;
    background-image: url("../images/slider-bg.png"),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3) 10%,
        rgba(255, 255, 255, 1) 100%
      );
    position: relative;
    display: flex;
    align-items: flex-end;

    .abs-image {
      position: absolute;
      top: -77%;
      left: 10%;
    }

    .steam {
      position: relative;
      top: 40px;
      left: 125px;
      animation: steam 5s linear infinite 3s;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -200px;
        left: 280px;
        border-right: 0px solid transparent;
        border-top: 50px solid #d6d2d2;
        border-left: 10px solid #d6d2d2;
        border-bottom: 10px solid transparent;
        width: 40px;
        height: 70px;
        z-index: 4;
        border-radius: 80% 0 10% 10%;

        transform: rotate(168deg) skewY(10deg);
        filter: blur(7px);
      }

      &:after {
        top: -200px;
        left: 200px;
        z-index: 4;
        border-radius: 80% 0 10% 10%;

        transform: rotate(315deg) skewY(10deg);
        filter: blur(7px);
      }
    }
  }

  .slider-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0px 60px 40px 90px;

    @include mobile-max-1000px {
      flex-direction: column;
      gap: 20px;
      padding: 0 !important;
      //   padding-top: 90px !important;
    }

    .text-wrapper {
      z-index: 1;

      @include mobile {
        text-align: center;
      }

      .text {
        text-transform: uppercase;
        @include jost-semiBold(40px);
      }

      .text-second {
        @include comforter(65px);

        .text-second-color {
          color: $red;
          @include comforter(65px);
        }
      }
    }

    .learn-more-btn-wrapper {
      min-width: 140px;
      align-self: flex-end;

      @include mobile {
        align-self: center;
      }

      @include mobile-max-550px {
        padding-bottom: 0;
        flex-direction: column;
        align-self: unset;
      }

      .learn-more {
        height: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid $black;
        border-radius: 8px;
        text-transform: uppercase;
        @include jost-regular(13px);
        color: $primary;
        transition: 1.2s ease;

        &:hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }
}
