.woocommerce-MyAccount-navigation {
    background-color: $white;
    border-radius: 15px;

    @include mobile-max-800px {
        padding: 20px;
        height: auto;
    }

    .profile-nav-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
        display: flex;
        align-items: center;
        padding: 0 20px;
        list-style: none;
        margin-bottom: 0;

        @include mobile-max-800px {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.woocommerce-MyAccount-navigation-link {
  color: $primary;

    &:not(:last-child) {
      margin-right: 10px;

        a::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
          margin: auto;
          border: 1px solid $black;
          border-radius: 50%;
        }
    }

    a {
      position: relative;
      display: block;
      color: inherit;
      padding: 20px 0 20px 30px;
      @include jost-regular(15px);
    }

    &.is-active {
      color: $red;
    }
}

.woocommerce-MyAccount-navigation-link--edit-account a::before {
  background: url('../images/profile-dark.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--edit-address a::before {
  background: url('../images/profile-location.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--favorites a::before {
  background: url('../images/heart.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--myorders a::before {
  background: url('../images/cart.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--cashback a::before {
  background: url('../images/dollar.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--codes a::before {
  background: url('../images/promo.png') center no-repeat;
}

.woocommerce-MyAccount-navigation-link--customer-logout {
  margin-left: auto;
  background: url('../images/profile-nav-exit.png') left center no-repeat;
}
