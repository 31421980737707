/* Fonts mixins */
@mixin jost($size: 14px) {
  font-family: "Jost";
  font-size: $size;
}

@mixin jost-light($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 300;
}

@mixin jost-light-italic($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-style: italic;
  font-weight: 300;
}

@mixin jost-regular($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 400;
}

@mixin jost-regular-italic($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 400;
  font-style: italic;
}

@mixin jost-medium($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 500;
}

@mixin jost-medium-italic($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 500;
  font-style: italic;
}

@mixin jost-semiBold($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 600;
}

@mixin jost-semiBold-italic($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-style: italic;
  font-weight: 600;
}

@mixin jost-bold($size: 14px) {
  font-family: "Jost";
  font-size: $size;
  font-weight: 700;
}

@mixin comforter($size: 14px) {
  font-family: "Comforter";
  font-size: $size;
  font-weight: 400;
}

/* ARROW */
@mixin arrow($size, $color, $direction) {
  content: " ";

  border-color: transparent;
  border-style: solid;
  border-width: $size;

  display: block;
  width: 0;
  height: 0;

  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

/* RESPONSIVE */
@mixin mobile {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin desktop-medium {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin mobile-max-4340px {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin mobile-max-400px {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin mobile-max-550px {
  @media (max-width: 550px) {
    @content;
  }
}

@mixin mobile-max-900px {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin mobile-max-800px {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin mobile-max-1000px {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin desktop-max-1600px {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin tablet-small {
  @media (min-width: 576px) and (max-width: 991px) {
    @content;
  }
}

@mixin tablet-large {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1199px) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: 1200px) and (max-width: 1600px) {
    @content;
  }
}

@mixin mobile-640 {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin mobile-640 {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin mobile-370 {
  @media (max-width: 370px) {
    @content;
  }
}

@mixin mobile-458 {
  @media (max-width: 458px) {
    @content;
  }
}