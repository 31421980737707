.select-radio-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .select-radio-item-wrapper {
        max-width: 240px;
        height: 35px;
    }

    .select-radio {
        width: 12px;
        height: 12px;
        position: relative;
        left: 10px;
    }

    .select-radio::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 15px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
        border: 2px solid $white
    }
    
    .select-radio:checked::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $black;
        border: 2px solid $white;
    }

    .radio-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 35px;
        max-width: 240px;
        width: 100%;
        border: 1px solid $white;
        border-radius: 8px;
    }
    
    .select-radio-text {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        @include jost-medium(13px);
    }
}