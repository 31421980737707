.secondary-slider-container {
  padding: 0 20px;
  margin-bottom: 70px;
  position: relative;
  width: 100%;

  @include mobile {
    margin-bottom: 50px;
  }

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    margin-bottom: 20px;
    height: auto;

    .swiper-secondary {
      @include desktop {
        width: percentage(1 / 7);
      }
    }

    .slide {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .slide-image-wrapper {
        position: relative;
        padding-top: 76%;
        width: 100%;
        height: 100%;
        border: 1px solid $white;
        border-radius: 10px;
        overflow: hidden;

        .slide-image {
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 1s;
        }
      }

      .slider-text {
        text-align: center;
        text-transform: uppercase;
        color: $white;
        @include jost-regular(17px);
      }
    }

    .secondary-slide-slide {
      & .slider-text:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 0;
        height: 1px;
        background-color: $white;
        transition: width 0.8s;
      }

      &:hover,
      &.active {
        .slider-text::after {
          width: 100%;
        }
      }

      &:hover {
        .slide-image {
          transform: scale(1.1);
        }
      }
    }
  }

  .swiper-scrollbar {
    position: unset;
    width: 100%;
    height: 1px;
    background-color: $scrollbar;
    margin-bottom: 10px;

    .swiper-scrollbar-drag {
      height: 20px;
      width: 50px !important;
      background-color: $white;
      border-radius: 15px;
      top: -9px;
      z-index: 100;
    }

    .arrows-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  .food-slider {
    .swiper-slide {
      width: 230px;

      &.active {
        .slider-text {
          &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 100%;
            height: 1px;
            background-color: #fff;
          }
        }
      }
    }
  }
}

.secondary-slider-container + .how-order-container {
  margin-top: 110px !important;
  margin-bottom: 120px;

  @include mobile {
    margin-bottom: 50px;
    margin-top: 0 !important;
  }
}

.whole-menu-btn-wrapper + .how-order-container {
  margin-bottom: 100px;

  @include mobile {
    margin-bottom: 70px;
  }
}

.terms-container ~ .how-order-container {
  margin-bottom: 110px;

  &:last-child {
    margin-bottom: 100px !important;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.how-order-container + .how-order-container:last-of-type {
  margin-bottom: 100px !important;
}
