.profile-form {
    .profile-form-wrapper {

        .cashback-wrapper {

            .used-cashback-heading {
                .table-head {
                    .table-head-wrapper {
                        .table-head-heading {
                            padding: 10px 0;

                            .text {
                                text-transform: uppercase;
                                @include jost-medium(13px);
                                color: $primary;
                            }
                        }
                    }
                    .table-head-border-top-bot {
                        border-top: 1px solid $small-borders;
                        border-bottom: 1px solid $small-borders;
                    }
                }

                .table-body {
                    .table-body-wrapper {
                        .table-body-item {

                            .text { 
                                @include jost-light(17px);
                                color: $primary;
                            }

                            .text-color {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
}