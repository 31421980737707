.single-popup-container-inner {
    padding-top: 45px !important;
    padding-bottom: 30px !important;

    position: fixed;
    right: 0;
    top: 35%;
    max-width: 790px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 300;
    
    @include mobile {
        height: auto;
        top: 10%;
    }

    .single-popup-inner {
        max-width: 590px;
        padding-left: 50px;

        @include mobile {
            padding-left: 20px;
        }
        .popup-heading { 
            padding-bottom: 10px;
            @include comforter(60px);

            @include mobile {
                font-size: 45px;
            }
        }

        .popup-present-text {
            padding-right: 30px;
            margin-bottom: 30px;
            @include jost-regular(17px);
        }

        .slide-wrapper {
            height: 80px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 35px;

            .image-wrapper {
                width: 56px;
                border: 1px solid $small-borders;
                border-radius: 8px;
                overflow: hidden;
            }

            .drink-name { 
                max-width: 130px;
                @include jost-regular(17px);
            }
        }

        .slider-navigation-wrapper {
            position: relative;
            max-width: 236px;

            .slider-navigation-left { 
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -80px;
                left: 0;
                z-index: 10;
            }
            .slider-navigation-right { 
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -80px;
                right: 0;
                z-index: 10;
            }
        }

        .send-message-wrapper {
            margin-bottom: 20px;
        }

        .no-drink-btn {
            position: unset;
            box-shadow: none;
            border-radius: none;
            @include jost-regular(13px);
        }
    }

    .swiper { 
        max-width: 216px;
        margin: 0 !important;
        margin-bottom: 0 !important;
        overflow: hidden !important;
    }

    .swiper-slide {
        width: 216px;
    }

    &.active {
        opacity: 0;
        pointer-events: none;
    }
}
